import * as React from "react"
import { ReactSVG } from "react-svg"
import Logo from "./../assets/svg/365BOX-Icon-Colorized 2.svg"

import Layout from "../components/layout"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <div className="text-center space-y-12 mt-32">
      <div className="text-6xl">404</div>
      <div>
        <ReactSVG src={Logo} />
      </div>
      <div>{"Sieht aus als wären Sie vom Weg abgekommen"}</div>
      <div className=" pb-32">
        <Link
          className="py-2 px-4 bg-highlight text-background text bold"
          to={"/"}
        >
          Zurück Zum Start
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
